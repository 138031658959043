<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M6.427 23.033c-0.912 0-1.739-0.747-1.739-1.653s0.747-1.657 1.74-1.657c0.997 0 1.747 0.747 1.747 1.656s-0.829 1.653-1.748 1.655zM27.776 14.016c-0.18-1.323-1-2.4-2.080-3.227l-0.42-0.333-0.339 0.413c-0.659 0.747-0.92 2.071-0.84 3.060 0.080 0.749 0.32 1.493 0.739 2.072-0.339 0.173-0.757 0.333-1.080 0.503-0.76 0.249-1.499 0.333-2.24 0.333h-21.387l-0.080 0.493c-0.16 1.576 0.080 3.227 0.749 4.72l0.325 0.58v0.080c2 3.311 5.56 4.8 9.437 4.8 7.459 0 13.576-3.227 16.476-10.177 1.9 0.083 3.819-0.413 4.72-2.235l0.24-0.413-0.4-0.249c-1.080-0.659-2.56-0.747-3.8-0.413zM17.099 12.693h-3.237v3.227h3.24v-3.229zM17.099 8.636h-3.237v3.227h3.24v-3.223zM17.099 4.497h-3.237v3.227h3.24v-3.227zM21.059 12.693h-3.219v3.227h3.227v-3.229zM9.061 12.693h-3.217v3.227h3.229v-3.229zM13.101 12.693h-3.2v3.227h3.219v-3.229zM5.061 12.693h-3.195v3.227h3.237v-3.229zM13.101 8.636h-3.2v3.227h3.219v-3.223zM9.041 8.636h-3.192v3.227h3.217v-3.223z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#1488c6"
      }
  },
};
</script>