<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M1.16 24.343c-0.331 0.509-0.707 1.167-1.017 1.66-0.090 0.149-0.143 0.328-0.143 0.52 0 0.364 0.191 0.684 0.479 0.864l0.004 0.003 6.62 4.072c0.152 0.095 0.337 0.152 0.535 0.152 0.371 0 0.695-0.198 0.873-0.494l0.003-0.005c0.265-0.443 0.605-1.017 0.977-1.628 2.623-4.329 5.26-3.804 10.011-1.528l6.609 3.116c0.128 0.063 0.279 0.099 0.438 0.099 0.414 0 0.771-0.247 0.93-0.602l0.003-0.006 3.152-7.128c0.054-0.12 0.085-0.26 0.085-0.407 0-0.409-0.241-0.761-0.588-0.923l-0.006-0.003c-6.994-3.12-9.167-4.173-11.335-5.235l4.715 2.087c-8.956-4.329-16.539-4.043-22.344 5.387zM30.841 7.657c0.332-0.54 0.708-1.167 1.019-1.667 0.087-0.147 0.139-0.324 0.139-0.514 0-0.363-0.19-0.682-0.476-0.862l-0.004-0.002-6.619-4.073c-0.152-0.095-0.337-0.152-0.535-0.152-0.371 0-0.695 0.198-0.873 0.494l-0.003 0.005c-0.26 0.447-0.601 1.017-0.979 1.633-2.621 4.328-5.26 3.8-10.011 1.528l-6.584-3.121c-0.128-0.062-0.278-0.099-0.437-0.099-0.414 0-0.77 0.247-0.93 0.601l-0.003 0.006-3.152 7.128c-0.054 0.12-0.085 0.26-0.085 0.407 0 0.409 0.241 0.761 0.588 0.923l0.006 0.003c1.385 0.653 4.14 1.956 6.62 3.148 8.931 4.328 16.523 4.039 22.317-5.387z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "black"
      }
  },
};
</script>