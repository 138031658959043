<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M31.097 15.107l-15.097-15.107-15.107 15.107c-0.227 0.231-0.368 0.548-0.368 0.898s0.14 0.667 0.368 0.898l15.107 15.097 15.097-15.097c0.232-0.229 0.376-0.547 0.376-0.898s-0.144-0.669-0.376-0.898l-0-0zM16 20.735l-4.735-4.735 4.735-4.729 4.731 4.729z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "black"
      }
  },
};
</script>