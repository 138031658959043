<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M16.253 3.173c-0.086-0.002-0.187-0.003-0.289-0.003-5.719 0-10.539 3.854-12.002 9.106l-0.021 0.088c0.071-0.027-0.073 0.017 0 0-5.167 3.401-5.229 10.813-0.329 14.588l0.008-0.009-0.009 0.040c1.482 1.129 3.36 1.808 5.396 1.808 0.014 0 0.028-0 0.042-0h6.895l0.040 0.040h6.923c8.916 0.071 12.501-11.473 5.113-16.467-0.672-2.426-1.986-4.488-3.749-6.058l-0.012-0.011-0.057 0.057 0.008-0.067c-2.115-1.873-4.893-3.040-7.941-3.112l-0.015-0zM15.776 8.701c1.659-0.053 3.357 0.491 4.648 1.533 1.523 1.277 2.484 3.181 2.484 5.309 0 0.045-0 0.090-0.001 0.136l0-0.007v0.691c4.707-0.093 4.707 7.016 0 6.924h-6.924l-0.011 0.012v-0.053h-6.925c-0.516 0-1.005-0.113-1.444-0.316l0.021 0.009h0.001c-1.21-0.558-2.034-1.76-2.034-3.154 0-1.912 1.55-3.463 3.463-3.463 1.395 0 2.597 0.825 3.145 2.013l0.009 0.021 4.017-4.016c-1.314-1.701-3.194-2.909-5.354-3.344l-0.058-0.010c0.024-0.013 0.053-0.035 0.072-0.031 1.221-1.337 2.95-2.191 4.879-2.254l0.011-0z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#4285f4"
      }
  },
};
</script>