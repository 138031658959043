export default {
  getErrors(state) {
    return state.errorMessages;
  },
  getFooterTextFields(state){
    return Object.assign({}, state.textFields.footer);
  },
  getHomeTextFields(state){
    return Object.assign({}, state.textFields.home);
  },
  getProfileSummaryTextFields(state){
    return Object.assign({}, state.textFields.profileSummary);
  }
};
