<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 490 490"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <rect :fill="color" x="402.5" y="58.8" width="81.3" height="311.2"/>
		<rect :fill="color" x="113.2" y="58.8" width="263.3" height="311.2"/>
		<rect :fill="color" x="6.2" y="58.8" width="81.3" height="311.2"/>
		<path :fill="color" d="M176.6,25.7h136.9v47.4h25.7V12.8c0-7-5.8-12.8-12.8-12.8H163.7c-7,0-12.8,5.8-12.8,12.8v60.3h25.7L176.6,25.7z"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "black"
      }
  },
};
</script>