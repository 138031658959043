<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 45"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M26 23l3 3 10-10-10-10-3 3 7 7z">
    </path>
    <path 
        :fill="color" 
        d="M14 9l-3-3-10 10 10 10 3-3-7-7z">
    </path>
    <path 
        :fill="color" 
        d="M21.916 4.704l2.171 0.592-6 22.001-2.171-0.592 6-22.001z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "black"
      }
  },
};
</script>