<template>
  <div id="container">
    <nav id="headerbar">
      <Header />
    </nav>
    <div id="content">
      <router-view></router-view>
    </div>
    <div id="footer"><Footer /></div>
  </div>
</template>

<script>
import Header from "./components/utils/Header.vue";
import Footer from "./components/utils/Footer.vue"
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  async mounted() {
    this.$store.dispatch("resume/getAllProjects");
    this.$store.dispatch("generic/getTextFields", "NL");
  }
};
</script>

<style> 
:root {
  --background-color-1:rgb(37,108,51);
  --background-color-2:rgb(11, 68, 23);
  --background-color-3:rgb(169, 190, 167);
  --background-color-4:rgb(24, 83, 19);
  --background-color-5:rgb(55, 0, 107);
  --background-color-6:rgb(55, 0, 107);
  /* --color-1: rgb(0, 170, 28); */
  --color-1: rgb(37,108,51);
  /* --color-2: rgb(0, 90, 23); */
  --color-2: rgb(11, 68, 23);
  --color-3: rgb(250, 250, 250);
  --color-4: rgb(68, 117, 78);
  --color-5: rgb(186, 211, 188);
  --color-6: #0d250f;
  --color-7: #bad3ba;
  --color-8: #bad3ba;
  --text-color-main: #353535;
  --text-color-1: white;
  --text-color-2: #a7a7a7;
  --text-color-3: #464646;
  --text-color-4: var(--background-color-1);
  --text-color-5: #202020;
  --text-color-6: #38006d;
  --text-color-7: #38006d;

  --ms-tag-bg: var(--color-1);
  --ms-radius: 0px;
}

#container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#headerbar {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 10;
}

#footer {
  margin-top: auto;  
}

#content {
  padding: 10px;
}

body {
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e2e2e;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}
p {
  font-size: medium;
}
* {
  box-sizing: border-box;
}
.hidden {
  visibility: hidden;
}
.removed {
  visibility: none;
}
</style>
