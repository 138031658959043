import { db } from "../../firebase"

export default {
  addError(context, value) {
    context.commit("addError", value);
  },
  removeError(context, uid) {
    context.commit("removeError", uid);
  },
  getTextFields(context, lang) {

    const ref = db.ref();
    ref.child("textFields").get().then(query => {
      const data = query.val()
      context.commit("setTextFields", data[lang])      
    })
  },
};
