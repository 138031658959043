<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M16 25.093c-8.836 0-16-4.797-16-10.716s7.164-10.716 16-10.716 16 4.799 16 10.716-7.164 10.716-16 10.716zM18.449 7.851c-6.717 0-12.161 3.28-12.161 7.327s5.444 7.325 12.16 7.325 11.675-2.243 11.675-7.325-4.957-7.327-11.673-7.327zM24.367 20.259c0.608 0.184 1.116 0.38 1.606 0.608l-0.074-0.031c0.299 0.149 0.555 0.335 0.776 0.555l-0-0c0.139 0.147 0.257 0.315 0.349 0.499l0.006 0.012 3.817 6.435-6.169 0.003-2.884-5.417c-0.283-0.497-0.599-0.926-0.959-1.314l0.004 0.005c-0.181-0.201-0.441-0.329-0.731-0.333h-1.466v7.056l-5.457 0.001v-18.020h10.961c0 0 4.992 0.089 4.992 4.84 0 0.005 0 0.011 0 0.017 0 2.7-2.1 4.91-4.755 5.085l-0.015 0.001zM21.993 14.224l-3.305-0.001v3.064h3.305c0.006 0 0.013 0 0.019 0 0.835 0 1.512-0.677 1.512-1.512 0-0.017-0-0.034-0.001-0.051l0 0.002c0.001-0.024 0.002-0.052 0.002-0.080 0-0.788-0.639-1.427-1.427-1.427-0.037 0-0.074 0.001-0.111 0.004l0.005-0z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#276dc3"
      }
  },
};
</script>