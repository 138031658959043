import { db } from "./../../firebase"

export default {
  setSelectedProject(context, id) {
    context.commit("setSelectedProject", id);
  },
  getAllProjects(context) {
    
    const ref = db.ref();
    ref.child("projects").get().then(query => {
      context.commit("setProjects", query.val())
      const data = query.val()
      
      const names = [];
      for (let i in data){
        for (let s in data[i].skills){
          let skill = data[i].skills[s]
          if (!names.find(n => n === skill))
            names.push(skill)
        }
      }
      names.sort()
      context.commit("setSkillNames", names)
    })
  },
};




