<template>
  <li class="card" @click="$emit('click')">
    <div class="card__text-info">
      <h1 class="company">{{ project.company }}</h1>
      <h1 class="role">{{ project.role }}</h1>
      <h2>{{ project.formatPeriod() }}</h2>
    </div>
    <div class="card__skill-info">
      <skill
        v-for="(skill, index) in cropList(project.skills)"
        :key="index"
        :skill="skill"
        :maxLength="15"
      ></skill>
    </div>
  </li>
</template>

<script>
import Skill from "./Skill.vue";

export default {
  components: { Skill },
  props: ["project"],
  methods: {
    cropList(list) {
      if (list != null && list.length > 8) return list.slice(0, 8);
      else return list;
    },
  },
};
</script>

<style scoped>
.card {
  display: grid;
  flex-basis: 35%;
  margin: 10px;
  flex-grow: 1;
  grid-template-columns: 50% 50%;
  min-width: 400px;
  width: 100%;
}
.card__skill-info {
  margin: auto;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  max-height: 120px;
  align-items: flex-start;
}
.card__text-info {
  flex-grow: 1;
  margin: 2px;
}
li {
  padding: 10px 20px 10px 20px;
  background-color: var(--color-3);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.role:hoverli:hover {
  background-color: var(--color-1);
  cursor: pointer;
  color: white;
}
.company {
  font-size: x-large;
}
.role {
  font-size: large;
  color: var(--text-color-4);
}

h2 {
  font-size: medium;
}

@media screen and (max-width: 500px) {
  .card {
    grid-template-columns: 100%;
    min-width: 300px;
  }
  .card__text-info {
    text-align: center;
  }
  .card__skill-info {
    margin: 10px 0px 0px 0px;
    flex-wrap: wrap;
  }
}

</style>
