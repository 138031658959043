import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDdY46SqAAjfEsU_RECr9wJ5sdbxa5PFOg",
  authDomain: "hitco-prod.firebaseapp.com",
  projectId: "hitco-prod",
  databaseURL: 'https://hitco-prod-default-rtdb.europe-west1.firebasedatabase.app/',
  storageBucket: "hitco-prod.appspot.com",
  messagingSenderId: "162592585916",
  appId: "1:162592585916:web:b3e5d128c36e1de8d738b9",
  measurementId: "G-GS1C7ZRGDG"
}

firebase.initializeApp(firebaseConfig);
const fs = firebase.firestore();
const db = firebase.database();
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 9000)
// }

export {db, fs};

