<template>
  <div>
    <h1>{{ homeText.title }}</h1>
    <p class="intro">
      {{ homeText.intro }}
    </p>
    <p>
      {{ profileText.summary }}
    </p>
    <p>
      {{ profileText.skills }}
    </p>
    <p>
      {{ profileText.interests }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      profileText: "generic/getProfileSummaryTextFields",
      homeText: "generic/getHomeTextFields",
    }),
  },
};
</script>

<style scoped>
.intro {
  font-weight: bold;
}
p,
h1 {
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}
</style>