import VueRouter from "vue-router";
import Vue from 'vue'

import Resume from "./pages/resume.vue";
import Home from "./pages/home.vue";

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: "/", component: Home, name: "Home" },
    { path: "/ervaring", component: Resume, name: "Ervaring" },
  ],
  base: process.env.VUE_APP_BASE_ROUTE,
});

export default router;
