<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M9.437 31.4c-0.631-0.421-1.191-0.937-1.659-1.533-0.511-0.617-0.984-1.267-1.419-1.939-1.021-1.493-1.82-3.127-2.373-4.848-0.667-2.003-0.991-3.92-0.991-5.796 0-2.093 0.453-3.92 1.336-5.453 0.653-1.2 1.627-2.204 2.8-2.909 1.133-0.707 2.453-1.093 3.787-1.12 0.467 0 0.973 0.067 1.507 0.2 0.387 0.107 0.853 0.28 1.427 0.493 0.733 0.28 1.133 0.453 1.267 0.493 0.427 0.16 0.787 0.227 1.067 0.227 0.213 0 0.52-0.067 0.86-0.173 0.193-0.067 0.56-0.187 1.080-0.413 0.515-0.187 0.923-0.347 1.247-0.467 0.493-0.147 0.971-0.28 1.4-0.347 0.52-0.080 1.036-0.107 1.531-0.067 0.947 0.067 1.813 0.267 2.587 0.56 1.36 0.547 2.457 1.4 3.276 2.613-0.347 0.213-0.667 0.461-0.967 0.733-0.649 0.573-1.2 1.253-1.64 2.007-0.573 1.027-0.867 2.187-0.859 3.36 0.020 1.444 0.387 2.713 1.12 3.813 0.516 0.8 1.205 1.485 2.045 2.048 0.413 0.28 0.776 0.473 1.12 0.6-0.16 0.5-0.336 0.987-0.54 1.467-0.463 1.076-1.013 2.107-1.667 3.080-0.576 0.84-1.029 1.467-1.373 1.88-0.536 0.64-1.053 1.12-1.573 1.463-0.573 0.38-1.247 0.581-1.936 0.581-0.467 0.020-0.933-0.040-1.379-0.169-0.387-0.127-0.768-0.269-1.141-0.431-0.391-0.179-0.795-0.331-1.207-0.453-0.507-0.133-1.027-0.197-1.552-0.196-0.533 0-1.053 0.067-1.547 0.193-0.413 0.117-0.813 0.261-1.209 0.433-0.56 0.233-0.927 0.387-1.14 0.453-0.432 0.128-0.875 0.205-1.32 0.233-0.693 0-1.339-0.2-1.964-0.617zM18.576 6.787c-0.907 0.453-1.768 0.645-2.631 0.581-0.133-0.861 0-1.747 0.36-2.716 0.32-0.827 0.747-1.573 1.333-2.24 0.613-0.693 1.347-1.267 2.173-1.68 0.88-0.453 1.72-0.693 2.52-0.733 0.107 0.907 0 1.8-0.333 2.76-0.304 0.853-0.757 1.64-1.333 2.347-0.58 0.693-1.3 1.267-2.089 1.681z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#999"
      }
  },
};
</script>