<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path
      :fill="color1"
      d="M5.757,7.288v36.111c0,3.749,8.392,6.789,18.743,6.789v-42.9C24.5,7.288,5.757,7.288,5.757,7.288z"
    />
    <path
      :fill="color2"
      d="M24.243,50.187H24.5c10.351,0,18.743-3.038,18.743-6.788V7.288h-19V50.187z"
    />
    <path
      opacity="0.15"
      fill="#FFFFFF"
      d="M24.243,50.187H24.5c10.351,0,18.743-3.038,18.743-6.788V7.288h-19V50.187z"
    />
    <path
      fill="#FFFFFF"
      d="M43.243,7.288c0,3.749-8.392,6.788-18.743,6.788S5.757,11.037,5.757,7.288S14.149,0.5,24.5,0.5
	S43.243,3.539,43.243,7.288"
    />
    <path
      :fill="color3"
      d="M39.411,6.897c0,2.475-6.676,4.479-14.911,4.479S9.588,9.372,9.588,6.897c0-2.474,6.677-4.479,14.912-4.479
	S39.411,4.423,39.411,6.897"
    />
    <path
      :fill="color4"
      d="M36.287,9.634c1.952-0.757,3.125-1.705,3.125-2.735c0-2.475-6.676-4.48-14.912-4.48
	c-8.235,0-14.911,2.005-14.911,4.48c0,1.03,1.173,1.978,3.125,2.735C15.44,8.576,19.7,7.893,24.5,7.893
	C29.301,7.893,33.559,8.576,36.287,9.634"
    />
    <path
      fill="#FFFFFF"
      d="M18.547,32.354c0,1.122-0.407,1.991-1.221,2.607c-0.814,0.616-1.938,0.924-3.373,0.924
	c-1.221,0-2.241-0.22-3.061-0.66v-2.64c0.946,0.803,1.988,1.205,3.126,1.205c0.55,0,0.975-0.11,1.275-0.33s0.45-0.511,0.45-0.875
	c0-0.357-0.144-0.668-0.433-0.932s-0.876-0.605-1.761-1.023c-1.804-0.846-2.706-2.002-2.706-3.464c0-1.061,0.393-1.912,1.18-2.553
	c0.786-0.64,1.831-0.961,3.134-0.961c1.155,0,2.111,0.152,2.871,0.454v2.466c-0.797-0.55-1.705-0.825-2.722-0.825
	c-0.511,0-0.915,0.108-1.212,0.325c-0.297,0.218-0.445,0.508-0.445,0.87c0,0.374,0.119,0.681,0.359,0.92
	c0.239,0.239,0.73,0.535,1.472,0.887c1.106,0.523,1.893,1.053,2.364,1.592C18.312,30.881,18.547,31.552,18.547,32.354z"
    />
    <path
      fill="#FFFFFF"
      d="M31.274,29.682c0,1.391-0.317,2.599-0.949,3.621c-0.633,1.023-1.523,1.74-2.672,2.153l3.431,3.176H27.62
	l-2.45-2.747c-1.05-0.038-1.998-0.316-2.842-0.833c-0.844-0.516-1.496-1.225-1.955-2.124s-0.689-1.902-0.689-3.007
	c0-1.226,0.249-2.319,0.746-3.279c0.498-0.96,1.197-1.698,2.099-2.215c0.902-0.516,1.935-0.775,3.102-0.775
	c1.088,0,2.063,0.25,2.924,0.751c0.86,0.5,1.528,1.212,2.004,2.136C31.036,27.463,31.274,28.511,31.274,29.682z M28.47,29.831
	c0-1.199-0.261-2.146-0.784-2.842s-1.237-1.044-2.145-1.044c-0.924,0-1.663,0.349-2.219,1.047c-0.555,0.699-0.833,1.628-0.833,2.788
	c0,1.155,0.272,2.077,0.816,2.767c0.545,0.69,1.267,1.035,2.169,1.035c0.919,0,1.647-0.334,2.186-1.002
	C28.2,31.913,28.47,30.996,28.47,29.831z"
    />
    <polygon
      fill="#FFFFFF"
      points="40.273,35.679 33.229,35.679 33.229,23.851 35.893,23.851 35.893,33.518 40.273,33.518 "
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color1() {
      return this.iconColor || "#0072C6";
    },
    color2() {
      return this.iconColor || "#0072C6";
    },
    color3() {
      return this.iconColor || "#7FBA00";
    },
    color4() {
      return this.iconColor || "#B8D432";
    },
  },
};
</script>