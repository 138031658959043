<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M21.683 17.724c-1.247 0-2.363 0.552-3.128 1.421l-1.96-1.388c0.208-0.573 0.328-1.188 0.328-1.832 0-0.633-0.115-1.237-0.316-1.803l1.956-1.373c0.765 0.865 1.879 1.413 3.121 1.413 2.3 0 4.172-1.871 4.172-4.172s-1.872-4.172-4.172-4.172-4.172 1.871-4.172 4.172c0 0.412 0.063 0.809 0.173 1.185l-1.957 1.373c-0.817-1.012-1.995-1.721-3.336-1.937v-2.359c1.889-0.397 3.313-2.076 3.313-4.081 0-2.301-1.872-4.172-4.172-4.172s-4.171 1.871-4.171 4.172c0 1.979 1.387 3.637 3.239 4.063v2.389c-2.527 0.444-4.455 2.649-4.455 5.301 0 2.665 1.947 4.879 4.493 5.308v2.523c-1.871 0.411-3.276 2.080-3.276 4.072-0.001 2.301 1.869 4.172 4.169 4.172s4.172-1.871 4.172-4.172c0-1.993-1.405-3.661-3.276-4.072v-2.523c1.288-0.217 2.453-0.895 3.28-1.908l1.973 1.397c-0.109 0.372-0.171 0.765-0.171 1.173 0 2.3 1.872 4.172 4.172 4.172s4.172-1.871 4.172-4.172-1.873-4.172-4.173-4.172zM21.683 7.969c1.116 0 2.023 0.908 2.023 2.023s-0.907 2.023-2.023 2.023c-1.115 0-2.023-0.908-2.023-2.023s0.908-2.023 2.023-2.023zM9.511 4.172c0-1.115 0.907-2.023 2.023-2.023s2.023 0.908 2.023 2.023-0.907 2.023-2.023 2.023-2.023-0.908-2.023-2.023zM13.556 27.828c0 1.115-0.907 2.023-2.023 2.023s-2.023-0.908-2.023-2.023 0.907-2.023 2.023-2.023 2.023 0.908 2.023 2.023zM11.533 18.747c-1.556 0-2.821-1.265-2.821-2.821s1.265-2.821 2.821-2.821 2.821 1.265 2.821 2.821-1.265 2.821-2.821 2.821zM21.683 23.919c-1.116 0-2.023-0.908-2.023-2.023s0.907-2.023 2.023-2.023 2.023 0.908 2.023 2.023-0.907 2.023-2.023 2.023z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "black"
      }
  },
};
</script>