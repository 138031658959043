<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 600 600"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path
      d="M465.479,156.573c-1.584,0.634 -2.535,2.535 -1.584,4.12c-0,0.316 0.317,0.316 0.317,0.316c1.584,3.486 1.267,7.923 -1.268,10.775c-1.268,1.267 -1.268,3.169 -0.317,4.436l0.317,0.317c0.634,0.317 1.584,0.317 2.218,0c0.951,0 1.902,-0.634 2.535,-1.268c4.437,-5.07 5.071,-12.041 2.219,-18.062c-1.585,-1.268 -3.169,-1.585 -4.437,-0.634Z"
      :fill="color"
    />
    <path
      d="M336.822,13.973c-158.127,-20.598 -302.312,91.264 -322.593,249.392c-20.281,158.127 91.264,302.629 249.392,322.909c158.127,20.281 302.312,-91.58 322.91,-249.391c20.281,-158.128 -91.264,-302.629 -249.709,-322.91c0.317,0 0.317,0 0,0Zm59.575,179.676c-5.387,0.317 -10.457,2.218 -14.26,6.021l-107.108,107.108l-22.816,-22.816c105.524,-105.207 124.537,-106.157 144.184,-90.313Zm-116.932,116.932l106.792,-105.841c6.021,-5.704 15.211,-5.704 21.231,0c2.852,2.852 4.437,6.972 4.437,10.774c-0.317,4.12 -1.901,7.923 -5.07,10.775l-112.813,99.503l-14.577,-15.211Zm7.923,16.161l-26.619,6.655c-0.634,0.317 -0.951,0.317 -1.585,0c-0.316,-0.634 -0.316,-1.267 0,-1.584l16.162,-16.162l12.042,11.091Zm-67.498,-11.091l28.203,-28.203l21.232,21.866l-48.484,10.457c-0.951,0.317 -2.218,-0.634 -2.218,-1.585c-0.317,-0.633 -0,-1.267 0.633,-1.584l0.634,-0.951Zm-87.461,146.72c-0.634,-0.317 -1.268,-0.634 -1.585,-1.268c-0.317,-0.633 -0.317,-1.267 0,-1.901l22.816,-22.816l29.471,29.154l-50.702,-3.169Zm58.307,-30.421c-2.218,1.267 -3.485,3.485 -2.852,6.02l4.754,20.915c0.317,1.268 -0.317,2.852 -1.268,3.486c-0.95,0.951 -2.535,0.951 -3.486,-0l-29.47,-30.421l90.313,-90.631l43.731,-9.506l20.914,20.914c-37.392,31.055 -78.588,57.674 -122.636,78.589l0,0.634Zm127.073,-82.392l-19.964,-19.964l112.812,-99.186c0.951,-0.95 2.219,-1.901 2.852,-3.169c-3.802,32.006 -49.117,77.955 -95.7,122.319Zm87.778,-155.275c-16.795,-17.429 -16.161,-45.315 1.268,-62.11c15.844,-15.211 40.561,-16.162 57.673,-2.536l-38.66,38.661c-0.951,1.267 -0.951,2.852 0,4.119l30.104,30.105c-17.112,8.239 -37.392,4.753 -50.702,-8.556l0.317,0.317Zm62.11,-0c-2.218,1.901 -4.119,3.803 -6.654,5.387l-28.837,-28.837l36.759,-36.759c15.844,16.795 15.527,43.414 -1.268,60.209Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "#ff6c37";
    },
  },
};
</script>