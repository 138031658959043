<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M0 16v16h32v-32h-32zM25.788 14.725c0.813 0.203 1.432 0.564 2.001 1.153 0.295 0.315 0.732 0.888 0.767 1.027 0.011 0.040-1.381 0.973-2.224 1.497-0.031 0.020-0.153-0.112-0.289-0.315-0.413-0.6-0.844-0.859-1.504-0.904-0.971-0.067-1.595 0.441-1.589 1.289-0.001 0.015-0.001 0.032-0.001 0.050 0 0.201 0.051 0.391 0.14 0.557l-0.003-0.006c0.213 0.441 0.611 0.707 1.853 1.244 2.292 0.987 3.272 1.636 3.881 2.56 0.68 1.031 0.833 2.677 0.371 3.901-0.507 1.331-1.767 2.235-3.54 2.533-0.548 0.097-1.848 0.083-2.437-0.024-1.285-0.229-2.504-0.864-3.256-1.697-0.295-0.324-0.869-1.173-0.833-1.233 0.015-0.021 0.147-0.103 0.293-0.188 0.144-0.081 0.681-0.392 1.189-0.687l0.92-0.533 0.193 0.285c0.269 0.411 0.857 0.975 1.213 1.163 1.021 0.539 2.423 0.463 3.113-0.157 0.257-0.217 0.419-0.54 0.419-0.9 0-0.021-0.001-0.042-0.002-0.062l0 0.003c0-0.371-0.047-0.533-0.24-0.813-0.248-0.355-0.756-0.653-2.199-1.28-1.651-0.711-2.361-1.152-3.012-1.853-0.392-0.445-0.694-0.981-0.871-1.57l-0.008-0.030c-0.121-0.452-0.152-1.585-0.056-2.041 0.34-1.596 1.544-2.707 3.281-3.037 0.564-0.107 1.875-0.067 2.428 0.071zM18.276 16.061l0.011 1.311h-4.167v11.835h-2.947v-11.835h-4.163v-1.285c0-0.712 0.015-1.307 0.035-1.32 0.016-0.021 2.551-0.032 5.623-0.027l5.593 0.016z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#007acc"
      }
  },
};
</script>