<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 400 400"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <polygon :fill="color" points="0,127.5 0,274.219 104.8,206.1 	" />
    <polygon :fill="color" points="400,274.219 400,127.5 295.2,206.1 	" />
    <polygon
      :fill="color"
      points="200,277.5 130.357,225.268 0,310 0,340 400,340 400,310 269.643,225.268 	"
    />
    <polygon :fill="color" points="0,90 200,240 400,90 400,60 0,60 	" />

  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "black";
    },
  },
};
</script>