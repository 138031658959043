export default {
  addError(state, message) {
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    state.errorMessages[uuid] = message;
  },
  removeError(state, uid) {
    if (uid in state.errorMessages) delete state.errorMessages[uid];
  },
  setTextFields(state, data){
    state.textFields = data;
  }
};
