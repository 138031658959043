export default {
  getProjects(state) {
    console.log(state.projects)
    state.projects.sort(function(a, b) {
      if (a.periodStart < b.periodStart) {
        return 1;
      } else if (a.periodStart > b.periodStart) {
        return -1;
      } else {
        return 0;
      }
    });
    return state.projects;
  },
  getSkillMapper(state){
    return state.skillIconMapper;
  },
  getSelectedProject(state) {
    const result = state.projects.filter(
      (x) => x.id == state.selectedProject
    )[0];
    return result;
  },
  getSkillIconClasses(state) {
    return state.skillSymbolMapper;
  },
  getCategoryIcons(state){
    return state.categoryIcons;
  },
  getSkillNames(state){
    return state.skillNames
  }
};
