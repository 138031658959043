<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M16.237 0.003c-0.069-0.001-0.151-0.002-0.232-0.002-4.42 0-8.421 1.792-11.317 4.688l-0 0c-2.895 2.896-4.685 6.895-4.685 11.313s1.79 8.418 4.685 11.313l-0-0c2.896 2.895 6.895 4.685 11.313 4.685s8.418-1.79 11.313-4.685l-0 0c2.895-2.896 4.685-6.895 4.685-11.313s-1.79-8.418-4.685-11.313l0 0c-2.841-2.842-6.745-4.62-11.065-4.686l-0.013-0zM10.36 7.993c0.749 0.171 1.4 0.289 2.217 0.289 1.228 0 2.484-0.289 3.715-0.289 2.387 0 4.6 1.085 4.6 3.733 0 2.053-1.228 3.356-3.133 3.907l3.717 5.476h1.735v1.347h-2.648l-4.391-6.579h-2.343v5.232h2.291v1.347h-5.76v-1.347h1.977v-11.597l-1.977-0.244zM16.315 9.343c-0.795 0-1.617 0.071-2.485 0.169v5.064l1.255 0.027c3.064 0.045 4.244-1.133 4.244-2.701 0-1.835-1.329-2.559-3.013-2.559z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#75aadb"
      }
  },
};
</script>