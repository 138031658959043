import Vuex from "vuex";
import Vue from 'vue'

import resumeModule from "./resume/index.js";
import genericModule from "./generic/index.js";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    resume: resumeModule,
    generic: genericModule,
  },
});

export default store;
