import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
      selectedProject: null,
      projects: [],
      skillIconMapper: {
        "Amazon Web Services": "aws",
        "Angular": "angular",
        "Appium": "appium", 
        "Azure": "azure",
        "Azure DevOps": "azuredevops",
        "Bitbucket": "bitbucket",
        "CSS": "css",
        "Confluence": "confluence",
        "Cucumber": "cucumber",
        "Citrus Framework": "citrus",
        "DAX": "",
        "Docker": "docker",
        "Docker-compose": "",
        "GIT": "git",
        "Google Cloud": "gcp",
        "HP-ALM": "",
        "HTML": "html5",
        "Hansen Generis": "",
        "IntelliJ": "intellij",
        "Java": "java",
        "Javascript": "javascript",
        "Jenkins": "jenkins",
        "Jira": "jira",
        "Kafka": "kafka",
        "Matlab": "",
        "MySQL": "mysql",
        "Orcale SQL": "oracle",
        "Parasoft LoadTest": "parasoft",
        "Parasoft SOAtest": "parasoft",
        "Parasoft Virtualize": "parasoft",
        "PostGIS": "",
        "PostgreSQL": "postgresql",
        "Power BI": "powerbi",
        "PyCharm": "pycharm",
        "Postmen": "postmen",
        "Python": "python",
        "R": "r",
        "React Native": "react",
        "R Studio": "rstudio",
        "Selenium": "selenium",
        "SQL server": "azuresql",
        "Typescript": "typescript"
      },
      categoryIcons: {
        "programming": "codeicon",
        "database": "database",
        "cloud": "tool",
        "workflow": "tool",
        "IDE": "tool",
        "test framework": "tool",
        "solution knowledge": "tool",
        "deployment": "tool"
      },
      skillNames: []
    };
  },
  mutations,
  getters,
  actions,
};

