
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 70 70"
  >
    <g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="24.9985"
          y1="27.0461"
          x2="66.6565"
          y2="27.0461"
        >
          <stop offset="0" style="stop-color: #21d789" />
          <stop offset="1" style="stop-color: #07c3f2" />
        </linearGradient>
        <polygon
          style="fill: url(#SVGID_1_)"
          points="49.1,11 69.5,28.1 62.2,43 49.8,39.6 39.2,39.6   "
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="-24.5594"
          y1="59.0806"
          x2="61.2196"
          y2="-4.2413"
        >
          <stop offset="1.075269e-002" style="stop-color: #fcf84a" />
          <stop offset="0.1116" style="stop-color: #a7eb62" />
          <stop offset="0.2056" style="stop-color: #5fe077" />
          <stop offset="0.2727" style="stop-color: #32da84" />
          <stop offset="0.3065" style="stop-color: #21d789" />
          <stop offset="0.5768" style="stop-color: #21d789" />
          <stop offset="0.5968" style="stop-color: #21d789" />
          <stop offset="0.686" style="stop-color: #20d68c" />
          <stop offset="0.7627" style="stop-color: #1ed497" />
          <stop offset="0.8348" style="stop-color: #19d1a9" />
          <stop offset="0.904" style="stop-color: #13ccc2" />
          <stop offset="0.9707" style="stop-color: #0bc6e1" />
          <stop offset="1" style="stop-color: #07c3f2" />
        </linearGradient>
        <polygon
          style="fill: url(#SVGID_2_)"
          points="28.5,22.1 24.5,43 24.1,50.2 14.2,54.5 0,56 4.3,10.7 29.9,0 45.7,10.4   "
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="9.3301"
          y1="77.6536"
          x2="23.6369"
          y2="32.7598"
        >
          <stop offset="0" style="stop-color: #21d789" />
          <stop offset="0.1638" style="stop-color: #24d788" />
          <stop offset="0.3047" style="stop-color: #2fd886" />
          <stop offset="0.4371" style="stop-color: #41da82" />
          <stop offset="0.5644" style="stop-color: #5adc7d" />
          <stop offset="0.6881" style="stop-color: #7ae077" />
          <stop offset="0.809" style="stop-color: #a1e36e" />
          <stop offset="0.9254" style="stop-color: #cfe865" />
          <stop offset="1" style="stop-color: #f1eb5e" />
        </linearGradient>
        <polygon
          style="fill: url(#SVGID_3_)"
          points="28.5,22.1 30.4,62.5 24,70 0,56 19.7,26.6   "
        />
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1="28.2753"
          y1="38.6231"
          x2="59.4088"
          y2="-3.2358"
        >
          <stop offset="0" style="stop-color: #21d789" />
          <stop offset="6.081050e-002" style="stop-color: #24d788" />
          <stop offset="0.1131" style="stop-color: #2fd886" />
          <stop offset="0.1622" style="stop-color: #41da82" />
          <stop offset="0.2094" style="stop-color: #5add7d" />
          <stop offset="0.2548" style="stop-color: #79e077" />
          <stop offset="0.2581" style="stop-color: #7ce076" />
          <stop offset="0.4986" style="stop-color: #8ce173" />
          <stop offset="0.9247" style="stop-color: #b2e56b" />
        </linearGradient>
        <polygon
          style="fill: url(#SVGID_4_)"
          points="54.9,19.1 30.6,19.1 52.1,0   "
        />
        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1="75.8893"
          y1="43.9496"
          x2="13.1579"
          y2="43.3687"
        >
          <stop offset="0.3871" style="stop-color: #fcf84a" />
          <stop offset="0.5358" style="stop-color: #ecf451" />
          <stop offset="0.8259" style="stop-color: #c2e964" />
          <stop offset="0.9247" style="stop-color: #b2e56b" />
        </linearGradient>
        <polygon
          style="fill: url(#SVGID_5_)"
          points="70,62.6 48.6,69.9 20.2,61.9 28.5,22.1 31.8,19.1 49.1,17.5 47.5,34.9 61.3,29.6       "
        />
      </g>
      <g>
        <rect
          x="13.4"
          y="13.4"
          style="fill: #000000"
          width="43.2"
          height="43.2"
        />
        <rect
          x="17.5"
          y="48.5"
          style="fill: #ffffff"
          width="16.2"
          height="2.7"
        />
        <path
          style="fill: #ffffff"
          d="M17.3,19.1h7.3c4.3,0,6.9,2.5,6.9,6.2v0.1c0,4.1-3.2,6.3-7.2,6.3h-3V37h-3.9V19.1z M24.4,28.1    c2,0,3.1-1.2,3.1-2.7v-0.1c0-1.8-1.2-2.7-3.2-2.7h-3v5.5H24.4z"
        />
        <path
          style="fill: #ffffff"
          d="M33.1,28.1L33.1,28.1c0-5.1,3.8-9.3,9.3-9.3c3.4,0,5.4,1.1,7.1,2.8l-2.5,2.9c-1.4-1.3-2.8-2-4.6-2    c-3,0-5.2,2.5-5.2,5.6V28c0,3.1,2.1,5.6,5.2,5.6c2,0,3.3-0.8,4.7-2.1l2.5,2.5c-1.8,2-3.9,3.2-7.3,3.2C37,37.3,33.1,33.2,33.1,28.1    "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "#231F20";
    },
  },
};
</script>