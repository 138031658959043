<template>
  <div @click.self="close" class="background">
    <div class="modal">
      <div>
        <h1>{{ info.company }}</h1>
        <h3>{{ info.formatPeriod() }}</h3>
        <h2>{{ info.role }}</h2>
        <p>{{ info.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    info() {
      return this.$store.getters["resume/getSelectedProject"];
    },
  },
  methods: {
    close: function () {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.modal {
  flex-grow: 1;
  max-width: 1000px;
  padding: 20px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  background-color: var(--color-3);
  position: absolute;
}
.background {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(94, 94, 94, 0.4);
  position: fixed;
  top: 0;
}
span {
  position: absolute;
  left: 20px;
}

h1 {
  color: var(--color-1);
}

h2 {
  color: var(--color-2);
}
p {
  size: 10em;
}
</style>
