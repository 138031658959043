<template>
  <ul class="header-content" :class="{ opaque: scrollPosition > 50 }">
    <li v-for="link in links" :key="link.name">
      <router-link :to="link.path">
        {{ link.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  created() {
    this.$router.options.routes.forEach((route) => {
      this.links.push({
        name: route.name,
        path: route.path,
      });
    });
  },
  data() {
    return {
      links: [],
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
img {
  background: white;
  height: 60px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 20px;
}
ul {
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  padding: 10px;
  margin: 0px;
  width: 100%;
  height: 80px;
}
.header-content {
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 1px var(--color-1) solid;
  border-color: var(--color-1);
}
li {
  list-style: none;
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -1px;
}
a {
  color: rgb(0, 0, 0);
  font-size: 25px;
  text-decoration: none;
}
.opaque {
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
