<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 500 500"
    role="presentation"
  >
    <path
      d="M22.5,20.5l121.6,-0l0,30.4l162.133,30.4l-162.133,20.267l0,40.533l-121.6,-0l0,-121.6Z"
      :fill="color"
    />
    <path
      d="M478.5,20.5l0,121.6l-30.4,-0l-30.4,162.133l-20.267,-162.133l-40.533,-0l0,-121.6l121.6,-0Z"
      :fill="color"
    />
    <path
      d="M478.5,476.5l-121.6,-0l0,-30.4l-162.133,-30.4l162.133,-20.267l0,-40.533l121.6,-0l0,121.6Z"
      :fill="color"
    />
    <path
      d="M22.5,476.5l0,-121.6l30.4,0l30.4,-162.133l20.267,162.133l40.533,0l0,121.6l-121.6,0Z"
      :fill="color"
    />
    <path
      d="M153.876,188.674l39.895,-10.69l2.672,9.974l55.866,-4.28l-51.411,20.903l3.563,13.298l-39.895,10.69l-10.69,-39.895Z"
      :fill="color"
    />
    <path
      d="M303.482,148.587l10.69,39.895l-9.974,2.672l4.279,55.866l-20.902,-51.411l-13.298,3.563l-10.69,-39.895l39.895,-10.69Z"
      :fill="color"
    />
    <path
      d="M343.569,298.193l-39.895,10.69l-2.673,-9.974l-55.865,4.279l51.411,-20.902l-3.563,-13.298l39.895,-10.69l10.69,39.895Z"
      :fill="color"
    />
    <path
      d="M193.963,338.28l-10.69,-39.895l9.974,-2.673l-4.28,-55.865l20.903,51.411l13.298,-3.563l10.69,39.895l-39.895,10.69Z"
      :fill="color"
    />
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "#1362a3";
    },
  },
};
</script>