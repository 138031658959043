<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 250 250"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <polygon
      points="125,30 125,30 125,30 31.9,63.2 46.1,186.3 125,230 125,230 125,230 203.9,186.3 218.1,63.2  "
      :fill="color1"
    />
    <polygon
      points="125,30 125,52.2 125,52.1 125,153.4 125,153.4 125,230 125,230 203.9,186.3 218.1,63.2 125,30  "
      :fill="color2"
    />
    <path
      d="M125,52.1L66.8,182.6h0h21.7h0l11.7-29.2h49.4l11.7,29.2h0h21.7h0L125,52.1L125,52.1L125,52.1L125,52.1   L125,52.1z M142,135.4H108l17-40.9L142,135.4z"
      :fill="color3"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color1() {
      return this.iconColor || "#dd0031";
    },
    color2() {
      return this.iconColor || "#c3002f";
    },
    color3() {
      return this.iconColor || "#ffffff";
    },
  },
};
</script>