<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M11.801 24.747c0 0-1.223 0.712 0.871 0.952 2.536 0.291 3.832 0.249 6.625-0.281 0 0 0.736 0.461 1.761 0.861-6.265 2.684-14.177-0.157-9.257-1.532zM11.035 21.244c0 0-1.371 1.015 0.723 1.232 2.709 0.279 4.848 0.303 8.551-0.411 0 0 0.512 0.519 1.316 0.803-7.572 2.215-16.009 0.173-10.589-1.624zM17.488 15.3c1.544 1.777-0.405 3.377-0.405 3.377s3.919-2.024 2.119-4.557c-1.681-2.363-2.971-3.536 4.009-7.584 0-0.001-10.955 2.735-5.723 8.764zM25.773 27.339c0 0 0.905 0.745-0.996 1.321-3.616 1.096-15.051 1.425-18.225 0.044-1.141-0.497 1-1.187 1.672-1.331 0.703-0.152 1.104-0.124 1.104-0.124-1.271-0.895-8.208 1.756-3.524 2.516 12.773 2.071 23.283-0.933 19.969-2.427zM12.389 17.613c0 0-5.816 1.381-2.059 1.883 1.585 0.212 4.748 0.164 7.693-0.083 2.408-0.203 4.824-0.636 4.824-0.636s-0.849 0.363-1.464 0.783c-5.905 1.553-17.315 0.831-14.029-0.757 2.776-1.341 5.035-1.189 5.035-1.189zM22.821 23.445c6.004-3.12 3.228-6.119 1.291-5.713-0.473 0.099-0.687 0.184-0.687 0.184s0.176-0.276 0.513-0.396c3.833-1.348 6.781 3.975-1.237 6.083 0-0.001 0.093-0.083 0.12-0.157zM19.201 0c0 0 3.325 3.325-3.153 8.44-5.195 4.103-1.184 6.443-0.001 9.115-3.032-2.737-5.257-5.144-3.765-7.385 2.192-3.292 8.263-4.887 6.92-10.169zM12.979 31.899c5.763 0.369 14.612-0.204 14.821-2.931 0 0-0.403 1.033-4.763 1.855-4.917 0.925-10.985 0.817-14.583 0.224 0-0.001 0.737 0.609 4.524 0.852z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#007396"
      }
  },
};
</script>