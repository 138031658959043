import Vue from 'vue'
import App from './App.vue'
import Router from "./router.js";
import Store from "./store/index.js";

Vue.config.productionTip = false

document.title = "Harmanny IT Consultancy"

new Vue({
  store: Store,
  router: Router,
  render: h => h(App),
}).$mount('#app')
