<template>
  <div class="root">
    <div class="search">
      <Multiselect
        class="search__item"
        :multiple="true"
        :searchable="true"
        select-label=""
        selectedLabel="Geselecteerd"
        deselect-label=""
        v-model="skillFilter"
        :options="skillList"
        placeholder="Zoek de skills waarop je wil filteren"
      ></Multiselect>
    </div>
    <div class="all-cards">
      <Card
        v-for="project in filteredProjects"
        :key="project.id"
        :project="project"
        @click="showModal(project.id)"
      ></Card>
    </div>
    <modal v-if="show" v-model="show"></modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Modal from "../components/resume/ProjectModal.vue";
import Card from "../components/resume/ProjectCard.vue";
import Multiselect from "vue-multiselect";

export default {
  components: { Modal, Card, Multiselect },
  data() {
    return {
      show: null,
      skillFilter: [],
    };
  },
  computed: {
    filteredProjects() {
      let allProjects = this.$store.getters["resume/getProjects"];
      if (this.skillFilter.length == 0) {
        return allProjects;
      } else {
        return allProjects.filter(this.filterProjectOnSkills);
      }
    },
    ...mapGetters({
      skillList: "resume/getSkillNames",
    }),
  },
  methods: {
    beforeEnter: function (el) {
      el.style.opacity = 0;
    },
    filterProjectOnSkills(project) {
      if (!('skills' in project)) return false
      var projectSkills = Object.keys(project.skills).map(
        (key) => project.skills[key]
      );
      for (let skill in this.skillFilter) {
        if (
          projectSkills.filter((s) => this.skillFilter[skill] == s).length > 0
        )
          return true;
      }
      return false;
    },
    showModal(id) {
      this.show = true;
      this.$store.dispatch("resume/setSelectedProject", id);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.root {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.search {
  padding: 5px 10px;
  width: 100%;
  z-index: 1;
  max-width: 1200px;
  position: sticky;
  top: 90px;
}
.search__item {
  width: 100%;
  padding: auto;
}
p {
  font-size: 8px;
}
.all-cards {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1200px;
  margin: auto;
  overflow: scroll;
  flex-grow: 1;
  width: 100%;
}
</style>
