<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 34 34"
  >
    <g>
      <defs>
        <rect x="0.9" y="0" width="32.2" height="33.9" />
      </defs> 
      <clipPath >
        <use xlink:href="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        :fill="color"
        d="M11.1,27.3c0,1.1-0.9,2.1-2.1,2.1s-2.1-0.9-2.1-2.1v-9c0-1.1,0.9-2.1,2.1-2.1   s2.1,0.9,2.1,2.1V27.3z"
      />
      <path
        :fill="color"
        d="M17.1,29.7c0,1.1-0.9,2.1-2.1,2.1S13,30.9,13,29.7V16c0-1.1,0.9-2.1,2.1-2.1   s2.1,0.9,2.1,2.1V29.7z"
      />
      <path
        :fill="color"
        d="M5,25.3c0,1.1-0.9,2.1-2.1,2.1s-2.1-0.9-2.1-2.1v-4.9c0-1.1,0.9-2.1,2.1-2.1   S5,19.3,5,20.4V25.3z"
      />
      <path
        :fill="color"
        d="M23.1,31.9c0,1.1-0.9,2.1-2.1,2.1C19.9,34,19,33,19,31.9V13.7   c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1V31.9z"
      />
      <path
        :fill="color"
        d="M29.7,31c-0.4,0-0.7-0.1-1.1-0.2L26.1,30c-0.4-0.1-0.7-0.6-0.5-1   c0.1-0.4,0.6-0.7,1-0.5l2.6,0.8c0.5,0.2,1.1,0.1,1.6-0.3c0.5-0.3,0.7-0.9,0.7-1.4V10.9c0-0.8-0.5-1.5-1.2-1.7L5.8,1.8   C5.3,1.6,4.7,1.7,4.2,2C3.8,2.4,3.5,2.9,3.5,3.5v11.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V3.5c0-1.1,0.5-2.1,1.4-2.7   c0.9-0.7,2-0.8,3-0.5l24.4,7.4c1.4,0.4,2.4,1.8,2.4,3.3v16.7c0,1.1-0.5,2.1-1.4,2.8C31.1,30.7,30.4,31,29.7,31"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "#231F20";
    },
  },
};
</script>