<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 300 300"
    role="presentation"
  >
    <g>
      <path
        d="M151.35,2.5c-71.492,0 -129.35,58.018 -129.35,129.708c0,63.119 45.121,115.564 104.665,127.232l-0,37.131c77.413,-11.704 145.958,-73.325 151.073,-154.214c3.078,-48.689 -21.131,-98.394 -61.96,-122.53c-2.338,-1.381 -4.713,-2.753 -7.159,-3.96c-2.492,-1.23 -5.051,-2.428 -7.652,-3.466c-0.826,-0.346 -1.635,-0.66 -2.469,-0.99c-2.271,-0.841 -4.563,-1.787 -6.911,-2.475c-12.658,-4.183 -26.188,-6.436 -40.237,-6.436Z"
        style="fill: #00a818; fill-rule: nonzero"
      />
      <path
        d="M178.146,50.427c-4.433,-0.456 -9.102,1.091 -12.367,4.961c-2.375,3.175 -3.859,6.25 -5.442,9.425c-4.749,11.112 -3.166,23.018 3.958,31.749c11.081,-2.381 20.48,-9.475 25.23,-20.587c1.583,-3.175 2.473,-7.243 2.473,-10.418c0.495,-8.433 -6.462,-14.37 -13.852,-15.13Zm-57.633,0.744c-7.227,0.69 -14.099,6.449 -14.099,14.386c0,3.175 0.89,7.243 2.474,10.418c4.749,11.112 14.89,18.206 25.972,20.587c6.332,-8.731 7.964,-20.637 3.215,-31.749c-1.583,-3.175 -3.067,-6.3 -5.442,-8.681c-3.265,-3.869 -7.783,-5.375 -12.12,-4.961Zm-38.834,38.198c-12.482,0.642 -18.057,16.37 -7.668,24.556c3.166,2.381 6.233,4.117 9.399,5.705c11.082,4.762 23.894,3.125 33.393,-3.225c-2.375,-10.318 -10.438,-19.793 -21.52,-24.556c-3.958,-1.587 -7.173,-2.48 -11.131,-2.48c-0.89,-0.099 -1.641,-0.043 -2.473,-0Zm121.945,48.119c-7.251,-0.139 -14.594,1.985 -20.531,5.953c2.375,10.319 10.439,19.794 21.52,24.556c3.958,1.587 7.173,2.48 11.131,2.48c14.248,0.794 21.223,-16.866 10.142,-24.803c-3.167,-2.382 -6.234,-3.87 -9.4,-5.457c-4.155,-1.786 -8.512,-2.645 -12.862,-2.729Zm-108.094,0.744c-4.35,0.084 -8.706,0.943 -12.862,2.729c-3.958,1.587 -7.025,3.324 -9.399,5.705c-10.29,8.731 -3.364,25.349 10.883,24.555c3.958,0 7.965,-0.892 11.131,-2.48c11.082,-4.762 18.947,-14.237 20.53,-24.556c-5.936,-3.968 -13.032,-6.092 -20.283,-5.953Zm40.072,24.308c-11.082,2.381 -20.481,9.475 -25.23,20.587c-1.583,3.175 -2.474,6.995 -2.474,10.17c-0.791,13.493 17.562,20.736 25.478,10.417c2.374,-3.175 3.858,-6.25 5.441,-9.425c4.749,-10.318 3.117,-22.224 -3.215,-31.749Zm29.435,0.744c-6.333,8.731 -7.965,20.637 -3.216,31.749c1.583,3.175 3.067,6.3 5.442,8.681c8.707,9.525 27.011,2.481 26.219,-9.425c0,-3.175 -0.89,-7.243 -2.473,-10.418c-4.749,-11.112 -14.891,-18.206 -25.972,-20.587Z"
        style="fill: #fff; fill-rule: nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "black";
    },
  },
};
</script>