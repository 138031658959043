<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M0 11.865l2.996-3.955 11.207-4.555v-3.296l9.827 7.191-20.075 3.896v10.965l-3.955-1.139zM32 5.932v19.536l-7.671 6.532-12.404-4.075v4.075l-7.971-9.888 20.076 2.397v-17.259z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#0078d7"
      }
  },
};
</script>