<template>
  <div class="container">
    <div class="text-box">
      <icon
        class="icon"
        iconName="mail"
        width="12px"
        height="12px"
        iconColor="white"
      ></icon>
      <a :href="'mailto:' + text.email">{{ text.email }}</a>
    </div>
    <div class="text-box">
      <icon
        class="icon"
        iconName="phone"
        width="12px"
        height="12px"
        iconColor="white"
      ></icon>
      <a :href="'tel:' + text.phone">{{ text.phone }}</a>
    </div>
    <div class="text-box">
      <icon
        class="icon"
        iconName="business"
        width="12px"
        height="12px"
        iconColor="white"
      ></icon>
      <p>{{ text.kvk }}</p>
    </div>
    <div class="text-box">
      <icon
        class="icon"
        iconName="locationpin"
        width="12px"
        height="12px"
        iconColor="white"
      ></icon>
      <p class="adress data">
        {{ text.street }} / {{ text.zipcode }} /
        {{ text.city }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default {
  components: { Icon },
  computed: {
    ...mapGetters({
      text: "generic/getFooterTextFields",
    }),
  },
};
</script>

<style scoped>
.container {
  background-image: linear-gradient(
    to right,
    var(--background-color-1) 0%,
    var(--background-color-2) 100%
  );
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  justify-content: center;
}
.text-box {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 10px 20px;
  border-left: white 1px solid;
  border-right: white 1px solid;
}
p,
a {
  text-align: left;
  font-size: small;
  color: var(--text-color-1);
  margin: 0;
}
.icon {
  margin: auto 5px auto 0;
}
@media screen and (max-width: 730px) {
  .container {
    flex-direction: column;
    align-items: stretch;
  }
  .text-box{
    border:none;
  }
}
</style>
