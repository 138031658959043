<template>
  <div class="container" @mouseover="toggleHover(true)" @mouseleave="toggleHover(false)">
    <component
      :is="iconName"
      :height="height"
      :width="width"
      :iconColor="iconColor"
    ></component>
    <div class=non-hover :class="{ hover: hover }">{{ hoverText }}</div>
  </div>
</template>

<script>
import icons from "./icons";

export default {
  components: { ...icons },
  data() {
    return { hover: false };
  },
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
    hoverText: {
      type: String,
      default: null,
    },
  },
  methods:{
    toggleHover(mode){
      if (this.hoverText != null){
        this.hover = mode
      }
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
.non-hover {
  display: hidden;
}
.container{
  position: relative;
  visibility: none;
}
.hover {
  display: inherit;
  position: absolute;
  overflow: visible;
  top: 20px;
  left: 0px;
  width: 200px;
  padding: 2px 4px 2px 4px;
  background-color: var(--color-3);
  opacity: 20;
}
</style>