
const periodFormatter = function () {
  const start = this.periodStart;
  const stop = this.periodStop;
  console.log(this.periodStop)
  console.log(stop)
  const fromMonth = "0" + (start.getMonth() + 1);
  const tillMonth = stop ? "0" + (stop.getMonth() + 1) : null;
  const from =
    start.getFullYear() + "/" + fromMonth.substr(fromMonth.length - 2);
  const till = stop
    ? stop.getFullYear() + "/" + tillMonth.substr(tillMonth.length - 2)
    : "heden";
  return from + " - " + till;
}

export default {
  setSelectedProject(state, id) {
    state.selectedProject = id;
  },
  setProjects(state, data) {
    for (var p in data) {
      data[p].periodStart = new Date(data[p].periodStart)
      if (data[p].periodStop) {
        data[p].periodStop = new Date(data[p].periodStop)
      }
      data[p].formatPeriod = periodFormatter;
    }
    state.projects = data;
  },
  setSkillNames(state, data) {
    state.skillNames = data
  }
};
