import aws from './svg/aws.vue'
import apache from './svg/apache.vue'
import azure from './svg/azure.vue'
import azuredevops from './svg/azuredevops.vue'
import bitbucket from './svg/bitbucket.vue'
import confluence from './svg/confluence.vue'
import css from './svg/css.vue'
import cucumber from './svg/cucumber.vue'
import docker from './svg/docker.vue'
import git from './svg/git.vue'
import gcp from './svg/gcp.vue'
import html5 from './svg/html5.vue'
import intellij from './svg/intellij.vue'
import java from './svg/java.vue'
import javascript from './svg/javascript.vue'
import jenkins from './svg/jenkins.vue'
import mysql from './svg/mysql.vue'
import oracle from './svg/oracle.vue'
import postgresql from './svg/postgresql.vue'
import python from './svg/python.vue'
import react from './svg/react.vue'
import r from './svg/r.vue'
import rstudio from './svg/rstudio.vue'
import typescript from './svg/typescript.vue'
import jira from './svg/jira.vue'
import apple from './svg/apple.vue'
import business from './svg/business.vue'
import database from './svg/database.vue'
import codeicon from './svg/code.vue'
import mail from './svg/mail.vue'
import tool from './svg/tool.vue'
import linkedin from './svg/linkedin.vue'
import locationpin from './svg/locationpin.vue'
import phone from './svg/phone.vue'
import www from './svg/www.vue'
import stackoverflow from './svg/stackoverflow.vue'
import powerbi from './svg/powerbi.vue'
import pycharm from './svg/pycharm.vue'
import selenium from './svg/selenium.vue'
import appium from './svg/appium.vue'
import angular from './svg/angular.vue'
import postmen from './svg/postmen.vue'
import parasoft from "./svg/parasoft.vue"
import azuresql from "./svg/azuresql.vue"
import citrus from "./svg/citrus.vue"

export default { azuresql, parasoft, citrus, aws, pycharm, cucumber, postmen, angular, appium, react, selenium, apache, azure, azuredevops, bitbucket, confluence, css, docker, git, gcp, html5, intellij, java, javascript, jenkins, mysql, oracle, postgresql, python, r, rstudio, typescript, jira, apple, business, database, codeicon, mail, tool, linkedin, locationpin, phone, www, stackoverflow, powerbi }