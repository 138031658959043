<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M21.883 5.883h-11.76c-0.002 0-0.003 0-0.005 0-5.588 0-10.117 4.53-10.117 10.117 0 5.586 4.527 10.114 10.112 10.117h11.771c5.588 0 10.117-4.53 10.117-10.117s-4.53-10.117-10.117-10.117v0zM21.625 22.552h-11.244c-3.562-0.074-6.421-2.979-6.421-6.552s2.859-6.478 6.414-6.552l0.007-0h11.244c3.619 0 6.552 2.933 6.552 6.552s-2.933 6.552-6.552 6.552v0z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#f80000"
      }
  },
};
</script>