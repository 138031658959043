<template>
  <div class="skill">
    <Icon
      width="15"
      height="15"
      :iconName="getIconClass(skill)" 
    />
    <p>{{ getSkillText(skill) }}</p>
  </div>
</template>

<script>
import Icon from "./../../components/utils/Icon.vue";
import { mapGetters } from "vuex";

export default {
  props: ["skill", "maxLength"],
  components: { Icon },
  computed: {
    ...mapGetters({
      skillIconMapper: "resume/getSkillMapper",
    }),
  },
  methods: {
    getIconClass(skill) {
      let result = this.skillIconMapper[skill];
      if (result) return result;
      else return "tool";
    },
    getSkillText(skill) {
      if (this.maxLength == null || skill.length < this.maxLength) {
        return skill;
      } else {
        return skill.substring(0, this.maxLength - 3) + "...";
      }
    },
  },
};
</script>

<style scoped>
.skill {
  z-index: -0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 3px 5px;
}
p {
  font-size: x-small;
  margin: 0px 0px 0px 10px;
}
</style>
