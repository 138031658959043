<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <path 
        :fill="color" 
        d="M1.037 1.615c-0.565-0.008-1.029 0.445-1.037 1.011 0 0.060 0.003 0.12 0.013 0.179l4.351 26.415c0.112 0.665 0.687 1.156 1.363 1.163h20.873c0.509 0.005 0.944-0.361 1.027-0.861l4.36-26.707c0.091-0.557-0.288-1.084-0.847-1.175-0.060-0.011-0.119-0.015-0.177-0.013zM19.36 20.704h-6.664l-1.803-9.419h10.081z">
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  computed: {
      color(){
          return this.iconColor || "#0052cc"
      }
  },
};
</script>